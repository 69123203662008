import React from "react";
import Layout from "../../layout/Layout/Layout";


const Dashboard = () => {
    return (
        <>
            <Layout>
                <main id="main" className="main">
                    <div className="pagetitle mt-3">
                        <h1>Dashboard</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item active">Dashboard</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section dashboard">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12">
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default Dashboard;