import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout/Layout";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import {AvField, AvForm, AvRadioGroup, AvRadio} from "availity-reactstrap-validation";
import {Button, FormGroup} from "reactstrap";
import Loader from "../loader/loader";
import {APP_CONFIGURATION} from "../../app-config/configuration";
import MultipleValueTextInput from 'react-multivalue-text-input';

interface IFormInput {
    title: string;
    desc: string;
    author: string;
    img: string;
    date: string;
    targetKeywords: string;
    metaTitle: string;
    metaDescription: string;
    slugUrl: string;
    tags: string;

}

interface BlogData {
    id: number;
    title: string;
    desc: string;
    author: string;
    img: string;
    date: string;
    targetKeywords: string;
    sourceData: string;
    metaTitle: string;
    metaDescription: string;
    slugUrl: string;
    tags: string;
}

interface Field {
    round: string;
    roundName: string;
    days: string;
    majorActivities: string;
}

interface Faq {
    question: string;
    answer: string;
}

interface KeyPhrases {
    phrases: string;
}

interface DetailRound {
    round: string;
    mname: string;
    details: string;
}

interface CreateBlogProps {
}

const CreateNewHackathon: React.FC<CreateBlogProps> = () => {
    const [fields, setFields] = useState<Field[]>([{round: '', roundName: '', days: '', majorActivities: ''}]);
    const [faq, setFaq] = useState<Faq[]>([{question: '', answer: ''}])
    const [phrases, setKeyPhrases] = useState<KeyPhrases[]>([{phrases: ''}])
    const [roundDetails, setRoundDetails] = useState<DetailRound[]>([{round: '', mname: '', details: ''}])
    const [stringPhrases, setStringPhrases] = useState<any>()


    const handleValidSubmit = (e: any, values: any) => {
        const payload = {
            hackathonName: values.hackathonName,
            overview: values.overview,
            rounds: fields,
            keyPhases: stringPhrases,
            prizeDetails: values.prizeDetails,
            rulesAndRegulations: values.rulesRagulations,
            email: values.email,
            phone: values.pNumber,
            additionalInformation: values.additionalInformation,
            faqs: faq,
            startDate: values.sDate,
            endDate: values.eDate,
            fileFormat: values.fileFormat,

        }
        const token = localStorage.getItem("auth_token")
        // setLoading(true)
        axios.post(`${APP_CONFIGURATION.APP_CONSTANT.UAT_AUTH_URL}wtl/api/v1/hackathon`, payload, {
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-type": "application/json",
                "Accept": "application/json"
            }
        })
            .then((res) => {
                window.location.reload();
            })
            .catch((err) => {
                console.log('err', err)
            });
    };

    const handleChangeSection = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newFields = [...fields];
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        newFields[index] = {
            ...newFields[index],
            [fieldName]: fieldValue
        };

        setFields(newFields);
    };
    const handleChangeFaqSection = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newFieldMain = [...faq];
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        newFieldMain[index] = {
            ...newFieldMain[index],
            [fieldName]: fieldValue
        };

        setFaq(newFieldMain);
    };

    const handlePhrasesChangeSection = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newFieldPhrases = [...phrases];
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        newFieldPhrases[index] = {
            ...newFieldPhrases[index],
            [fieldName]: fieldValue
        };
        let stringArray = newFieldPhrases.map(item => String(item.phrases));
        setStringPhrases(stringArray)
        setKeyPhrases(newFieldPhrases);
    };
    const addRowRoundDetailsSection = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
        const newFieldRound = [...roundDetails];
        const fieldName = event.target.name;
        const fieldValue = event.target.value;

        newFieldRound[index] = {
            ...newFieldRound[index],
            [fieldName]: fieldValue
        };

        setRoundDetails(newFieldRound)
    }


    const addRow = () => {
        setFields([...fields, {round: '', roundName: '', days: '', majorActivities: ''}]);
    };
    const addRowFaq = () => {
        setFaq([...faq, {question: '', answer: ''}])
    }
    const addRowPhrases = () => {
        setKeyPhrases([...phrases, {phrases: ''}])
    }
    const addRowRoundDetails = () => {
        setRoundDetails([...roundDetails, {round: '', mname: '', details: ''}])
    }

    // @ts-ignore
    return (
        <>
            <Layout>
                <main id="main" className="main">
                    <div className="pagetitle mt-3">
                        <h1>Create New Hackathon</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item active">Create New Hackathon</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section dashboard">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mt-5">
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1" className='label-style'>Hackathon
                                                Name*</label>
                                            <AvField required name='hackathonName'
                                                     type="text" className="form-control mt-2" id="exampleInputEmail1"
                                                     aria-describedby="emailHelp" placeholder="Enter Hackathon Name"/>

                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1"
                                                   className='label-style'>Overview*</label>
                                            <AvField
                                                required
                                                name='overview' type="text" className="form-control mt-2"
                                                id="exampleInputPassword1"

                                                placeholder="Enter Overview"/>
                                        </div>
                                        <div>
                                            <label htmlFor="exampleInputPassword1"
                                                   className='label-style mb-2'>Event Formats*</label>
                                            {fields.map((field, index) => (
                                                <div key={index} className="row">

                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Round*</label>
                                                        )}
                                                        <input
                                                            type="number"
                                                            placeholder="Enter Round"
                                                            name="round"
                                                            className="form-control mt-1"
                                                            value={field.round}
                                                            onChange={(e) => handleChangeSection(index, e)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Round Name*</label>
                                                        )}
                                                        <input
                                                            placeholder="Enter Round Name"
                                                            type="text"
                                                            name="roundName"
                                                            className="form-control mt-1"
                                                            value={field.roundName}
                                                            onChange={(e) => handleChangeSection(index, e)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Days*</label>
                                                        )}
                                                        <input
                                                            placeholder="Enter Days"
                                                            type="number"
                                                            name="days"
                                                            className="form-control mt-1"
                                                            value={field.days}
                                                            onChange={(e) => handleChangeSection(index, e)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Major
                                                                Activities*</label>
                                                        )}
                                                        <input
                                                            placeholder="Enter Major Activities"
                                                            required
                                                            type="text"
                                                            name="majorActivities"
                                                            className="form-control mt-1"
                                                            value={field.majorActivities}
                                                            onChange={(e) => handleChangeSection(index, e)}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='text-end btn-style'>
                                                <a className='btn text-white mt-2' onClick={addRow}>Add New Round</a>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="exampleInputPassword1"
                                                   className='label-style mb-2'>Key Phrases*</label>
                                            {phrases.map((phrasesItem, index) => (
                                                <div key={index} className="row">

                                                    <div className="col">
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Key Phrases"
                                                            name="phrases"
                                                            className="form-control mt-1"
                                                            value={phrasesItem.phrases}
                                                            onChange={(e) => handlePhrasesChangeSection(index, e)}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='text-end btn-style'>
                                                <a className='btn text-white mt-2' onClick={addRowPhrases}>Add New
                                                    Phase</a>
                                            </div>
                                        </div>
                                        <div>
                                            <label htmlFor="exampleInputPassword1"
                                                   className='label-style mb-2'>Details of Round*</label>
                                            {roundDetails.map((roundItem, index) => (
                                                <div key={index} className="row">
                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Round*</label>
                                                        )}
                                                        <input
                                                            type="number"
                                                            placeholder="Enter Round"
                                                            name="round"
                                                            className="form-control mt-1"
                                                            value={roundItem.round}
                                                            onChange={(e) => addRowRoundDetailsSection(index, e)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Name*</label>

                                                        )}
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Name"
                                                            name="mname"
                                                            className="form-control mt-1"
                                                            value={roundItem.mname}
                                                            onChange={(e) => addRowRoundDetailsSection(index, e)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Details*</label>
                                                        )}
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Details"
                                                            name="details"
                                                            className="form-control mt-1"
                                                            value={roundItem.details}
                                                            onChange={(e) => addRowRoundDetailsSection(index, e)}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='text-end btn-style'>
                                                <a className='btn text-white mt-2' onClick={addRowRoundDetails}>Add New
                                                    Round</a>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Submission
                                                Formats*</label>
                                            <AvRadioGroup inline name="fileFormat"
                                                          required className="mt-2">
                                                <AvRadio label="Zip" value="zip"/>
                                                <AvRadio label="Pdf" value="pdf"/>
                                                <AvRadio label="Ppt" value="ppf"/>
                                            </AvRadioGroup>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Prize
                                                Details*</label>
                                            <AvField required
                                                     name='prizeDetails' type="text" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     placeholder="Enter Prize Details"/>
                                        </div>

                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Rules &
                                                Regulations*</label>
                                            <AvField required
                                                     name='rulesRagulations' type="text" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     placeholder="Enter Rules & Regulation"/>
                                        </div>
                                        <div>
                                            <label htmlFor="exampleInputPassword1"
                                                   className='label-style mb-2'>FAQ*</label>
                                            {faq.map((faqItem, index) => (
                                                <div key={index} className="row">

                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Question*</label>
                                                        )}
                                                        <input
                                                            type="text"
                                                            placeholder="Enter Question"
                                                            name="question"
                                                            className="form-control mt-1"
                                                            value={faqItem.question}
                                                            onChange={(e) => handleChangeFaqSection(index, e)}
                                                        />
                                                    </div>
                                                    <div className="col">
                                                        {index === 0 && (
                                                            <label htmlFor="exampleInputPassword1"
                                                                   className='label-style mb-2'>Answer*</label>
                                                        )}
                                                        <input
                                                            placeholder="Enter Answer"
                                                            type="text"
                                                            name="answer"
                                                            className="form-control mt-1"
                                                            value={faqItem.answer}
                                                            onChange={(e) => handleChangeFaqSection(index, e)}
                                                        />
                                                    </div>
                                                </div>
                                            ))}
                                            <div className='text-end btn-style'>
                                                <a className='btn text-white mt-2' onClick={addRowFaq}>Add New FAQ</a>
                                            </div>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Support /
                                                Contacts Details*</label>
                                            <AvField required
                                                     name='email' type="email" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     placeholder="Enter Email"/>
                                            <AvField required
                                                     name='pNumber' type="number" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     placeholder="Enter Phone Number"
                                                     validate={{
                                                         required: {
                                                             value: true,
                                                             errorMessage: 'This field is required.'
                                                         },
                                                         pattern: {
                                                             value: '[1-9]{1}[0-9]{9}',
                                                             errorMessage: 'Phone number must be 10 digit.'
                                                         }
                                                     }}
                                            />
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Additional
                                                Informations*</label>
                                            <AvField required
                                                     name='additionalInformation' type="textarea"
                                                     className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     placeholder="Enter Additional Information"/>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Date &
                                                Deadline*</label>
                                            <div className="row">
                                                <div className="col">
                                                    <div className="form-group mt-3">
                                                        <label htmlFor="exampleInputPassword1"
                                                               className='label-style mb-2'>Start Date*</label>
                                                        <AvField required
                                                                 name="sDate"
                                                                 type="date"
                                                                 class="form-control mt-3"
                                                                 id="exampleInputPassword1"
                                                                 placeholder="Enter Additional Information"/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group mt-3">
                                                        <label htmlFor="exampleInputPassword1"
                                                               className='label-style mb-2'>Dead Line*</label>
                                                        <AvField required
                                                                 name="eDate"
                                                                 type="date"
                                                                 class="form-control mt-3"
                                                                 id="exampleInputPassword1"
                                                                 placeholder="Enter Additional Information"/>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <div className="form-group mt-3">
                                                        <label htmlFor="exampleInputPassword1"
                                                               className='label-style mb-2'>Round Used*</label>
                                                        <AvField required
                                                                 name="additionalInformations"
                                                                 type="texta"
                                                                 class="form-control mt-3"
                                                                 id="exampleInputPassword1"
                                                                 placeholder="Enter Additional Information"/>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <FormGroup className='mt-4 text-center btn-style'>
                                            <Button>Submit</Button>
                                        </FormGroup>
                                    </AvForm>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default CreateNewHackathon;