import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout/Layout";
import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";
import {useNavigate} from "react-router-dom";
// @ts-ignore
import {AvField, AvForm} from "availity-reactstrap-validation";
import {Button, FormGroup} from "reactstrap";
import Loader from "../loader/loader";
import {APP_CONFIGURATION} from "../../app-config/configuration";

interface IFormInput {
    title: string;
    desc: string;
    author: string;
    img: string;
    date: string;
    targetKeywords: string;
    metaTitle: string;
    metaDescription: string;
    slugUrl: string;
    tags: string;

}

interface BlogData {
    id: number;
    title: string;
    desc: string;
    author: string;
    img: string;
    date: string;
    targetKeywords: string;
    sourceData: string;
    metaTitle: string;
    metaDescription: string;
    slugUrl: string;
    tags: string;
}

interface CreateBlogProps {
}

const CreateBlog: React.FC<CreateBlogProps> = () => {
    const [singleBlogData, setSingleBlogData] = useState<BlogData | null>(null)
    const [editData, setEditData] = useState()
    const [uploadImage, setUploadImage] = useState()
    const [dateFormat, setDateFormat] = useState()
    const [editId, setEditId] = useState()
    const [isUpdate, setIsUpdate] = useState(false)
    const [loading, setLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    useEffect(() => {
        const currentURL = window.location.href;
        const documentId: any = currentURL.substring(currentURL.lastIndexOf("/") + 1);
        setEditId(documentId)

        const validate: any = documentId && currentURL.split("/").length === 4;
        setIsUpdate(validate)
        if (validate === false) {
            axios.get(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}api/v0/blog/${documentId}`)
                .then(async (res) => {
                    setSingleBlogData(res.data)
                    setEditorData(res.data.sourceData)
                }).catch(() => {
            })
        }
    }, []);
    const [editorData, setEditorData] = useState<string>('');

    const handleChange = (event: any, editor: any) => {
        const data = editor.getData();
        setEditorData(data);
    };
    console.log('isUpdate ', isUpdate)

    const handleValidSubmit = (e: any, values: IFormInput) => {
        if (isUpdate) {
            const payload = {
                title: values.title,
                author: values.author,
                targetKeywords: values.targetKeywords,
                sourceData: editorData,
                metaTitle: values.metaTitle,
                metaDescription: values.metaDescription,
                slugUrl: values.slugUrl,
                tags: values.tags,
                blogPic: uploadImage
            }
            setLoading(true)
            axios.post(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}api/v0/blog`, payload, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    'Authorization': localStorage.getItem("access")
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        // Assuming that your API returns a success status code
                        navigate('/blog');
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    // Handle the error
                });
        } else {
            const payload = {
                id: editId,
                title: values.title,
                author: values.author,
                targetKeywords: values.targetKeywords,
                sourceData: editorData,
                metaTitle: values.metaTitle,
                metaDescription: values.metaDescription,
                slugUrl: values.slugUrl,
                tags: values.tags,
                ...(uploadImage ? {blogPic: uploadImage} : {})
            }
            setLoading(true)
            axios.post(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}api/v0/updateBlog`, payload, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "multipart/form-data",
                    'Authorization': localStorage.getItem("access")
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        // Assuming that your API returns a success status code
                        navigate('/blog');
                        setLoading(false)
                    }
                })
                .catch((err) => {
                    // Handle the error
                });

        }
    };

    const handleFileChange = (e: any) => {
        // Access the selected file from the event
        const selectedFile = e.target.files[0];
        setUploadImage(selectedFile)
    };


    return (
        <>
            <Layout>
                <main id="main" className="main">
                    <div className="pagetitle mt-3">
                        <h1>Add/Update Blog</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a href="#">Home</a>
                                </li>
                                <li className="breadcrumb-item active">Blogs</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section dashboard">
                        <Loader loading={loading}/>
                        <div className="container">
                            <div className="row">
                                <div className="col-md-12 mt-5">
                                    <AvForm onValidSubmit={handleValidSubmit}>
                                        <div className="form-group">
                                            <label htmlFor="exampleInputEmail1" className='label-style'>Title*</label>
                                            <AvField required name='title'
                                                     value={singleBlogData ? singleBlogData.title : ''}
                                                     type="text" className="form-control mt-2" id="exampleInputEmail1"
                                                     aria-describedby="emailHelp" placeholder="Enter Title"/>

                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1"
                                                   className='label-style'>Author*</label>
                                            <AvField
                                                required
                                                name='author' type="text" className="form-control mt-2"
                                                value={singleBlogData ? singleBlogData.author : ''}
                                                id="exampleInputPassword1"

                                                placeholder="Enter Author"/>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Upload
                                                Image*</label>
                                            <AvField

                                                name='img' type="file" className="form-control mt-2"
                                                id="exampleInputPassword1"
                                                onChange={(e: any) => handleFileChange(e)}
                                                placeholder="Enter Author"/>
                                        </div>
                                        {singleBlogData &&
                                            <div className="form-group mt-3">
                                                <img src={singleBlogData ? singleBlogData.img : ''} alt='Blog Image'
                                                     height="200"/>
                                            </div>
                                        }

                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Tags
                                                Keywords*</label>
                                            <AvField required
                                                     name='targetKeywords' type="text" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     value={singleBlogData ? singleBlogData.targetKeywords : ''}
                                                     placeholder="Enter Keywords"/>
                                        </div>

                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Meta
                                                Title*</label>
                                            <AvField required
                                                     name='metaTitle' type="text" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     value={singleBlogData ? singleBlogData.metaTitle : ''}
                                                     placeholder="Enter Meta Title"/>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Meta
                                                Description*</label>
                                            <AvField required
                                                     name='metaDescription' type="text" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     value={singleBlogData ? singleBlogData.metaDescription : ''}
                                                     placeholder="Enter Meta Description"/>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Slug
                                                Url*</label>
                                            <AvField required
                                                     name='slugUrl' type="text" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     value={singleBlogData ? singleBlogData.slugUrl : ''}
                                                     placeholder="Enter Slug Url"/>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Tags*</label>
                                            <AvField name='tags' required
                                                     type="text" className="form-control mt-2"
                                                     id="exampleInputPassword1"
                                                     value={singleBlogData ? singleBlogData.tags : ''}
                                                     placeholder="Enter Tags"/>
                                        </div>
                                        <div className="form-group mt-3">
                                            <label htmlFor="exampleInputPassword1" className='label-style'>Source
                                                Data*</label>
                                            <CKEditor
                                                editor={ClassicEditor}
                                                data={editorData}
                                                onChange={handleChange}


                                            />
                                        </div>
                                        <FormGroup className='mt-4 text-center btn-style'>
                                            <Button>Submit</Button>
                                        </FormGroup>
                                    </AvForm>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </Layout>
        </>
    )
}
export default CreateBlog;