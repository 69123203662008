import React from 'react';

const Loader = (props:any) => {
    return (
        <>
            {props.loading ? <div
                id='pause'
                className='d-flex align-items-center justify-content-center'>
                <div className='spinner'/>
            </div> : ''}
        </>
    );
}
export default Loader;
