import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout/Layout";
import {Box} from "@mui/material";
import {DataGrid, GridCellParams} from "@mui/x-data-grid";
import axios from "axios";
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import {toast} from "react-toastify";
import Loader from "../loader/loader";
import {APP_CONFIGURATION} from "../../app-config/configuration";


interface BlogDataType {
    id: string;
    title: string;
    desc: string;
    author: string;
    img: string;
    date: string;
    slugUrl: string;
}

const Blog = () => {
    const [blogData, setBlogData] = useState<BlogDataType[]>([]);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)

    const onOpenModal = (id: any) => {
        setOpen(true);
        setDeleteId(id)
    }
    const onCloseModal = () => setOpen(false);

    const handleDelete = () => {
        const payload = {
            id: deleteId
        }
        setLoading(true)
        // @ts-ignore
        axios.post(`${APP_CONFIGURATION.APP_CONSTANT.API_URL}api/v0/deleteBlog`, payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((res) => {
                if (res.status) {
                    setLoading(false)
                    toast.success("Deleted Successfully");
                    onCloseModal()
                    getAllBlog()
                }
            })
            .catch((err) => {
                // Handle the error
            });
    }
    const handleEdit = (id: string) => {
        window.location.replace(`/create-blog/${id}`);
    }
    const columns = [
        {
            field: "srNo", // Update the field name to match the property name in the data
            headerName: "Sr. No.",
            width: 60,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as number)}</div>,
        },
        {
            field: "title",
            headerName: "Title",
            width: 495,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as string)}</div>,
        },
        // {
        //     field: "desc",
        //     headerName: "Description",
        //     width: 300,
        //     renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as string)}</div>,
        // },
        {
            field: "author",
            headerName: "Author",
            width: 150,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as string)}</div>,
        },
        {
            field: "img",
            headerName: "Image",
            width: 304,
            renderCell: (params: GridCellParams) => (
                <div style={{textAlign: 'center'}}>
                    <img src={(params.value as string)} alt={(params.value as string)} style={{width: "100%"}}/>
                </div>
            ),
        },
        {
            field: "date",
            headerName: "Date",
            width: 250,
            renderCell: (params: GridCellParams) => (
                <div style={{textAlign: 'center'}}>
                    {(params.value as string)}
                </div>
            ),

        },
        {
            field: "actions",
            headerName: "Actions",
            width: 190,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>
                <button style={{background: '#012970', border: 'none'}} className='btn btn-danger mr-4' type="button"
                        onClick={() => handleEdit(params.row.id)}>
                    Edit
                </button>
                <button className='btn btn-danger' type="button" onClick={() => onOpenModal(params.row.id)}
                        style={{marginLeft: '10px'}}>
                    Delete
                </button>
            </div>
        }
    ];

    useEffect(() => {
        getAllBlog()
    }, []);
    const getAllBlog = () => {
        setLoading(true)
        axios
            .get(`https://gatewayapi.worldtradingleagues.com/wtl/api/v0/blog`)
            .then((res) => {
                const blogDataWithKeys = res.data.map((item: BlogDataType, index: number) => ({
                    ...item,
                    id: item.id.toString(),
                    srNo: index + 1, // Add a new property for Sr. No.
                }));
                setBlogData(blogDataWithKeys);
                setLoading(false)
            })
            .catch(() => {
            });
    }

    // @ts-ignore
    return (
        <>
            <Layout>
                <main id="main" className="main">
                    <div className="pagetitle mt-3">
                        <h1>Blogs</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    Home
                                </li>
                                <li className="breadcrumb-item active">Blogs</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section dashboard">
                        <Loader loading={loading}/>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 mt-5">
                                    <Box>
                                        <DataGrid
                                            rows={blogData}
                                            columns={columns}
                                            pagination
                                            // rowCount={blogData.length}
                                            // pageSizeOptions={[10, 15, 25]}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Modal open={open} onClose={onCloseModal} center>
                    <div className='p-5 text-center'>
                        <h3>Are You Sure You Want to Delete?</h3>
                        <div className="mt-3">
                            <button className="btn btn-danger ml-5" onClick={handleDelete}>Yes</button>
                            <button className="btn btn-secondary mr-4" style={{marginLeft: '10px'}}
                                    onClick={onCloseModal}>Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
            </Layout>
        </>
    );
};

export default Blog;
