import React from "react";
import {SubmitHandler, useForm} from "react-hook-form";
import ErrorMsg from "../err-message/ErrorMsg";
import {Link} from 'react-router-dom';
import axios from "axios";
import {useNavigate} from 'react-router-dom';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {APP_CONFIGURATION} from "../../app-config/configuration";

interface IFormInput {
    username: string;
    password: string;
}

interface UserData {
    role: null | string;
    lastlogin: string;
    auth_token: string;
    bowSessionID: null | string;
    username: string;
}

const Login: React.FC = () => {
    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormInput>();

    const onSubmit: SubmitHandler<IFormInput> = async (data) => {
        try {
            const response = await axios.post(
                `https://gatewayapi-uat.invest19.com/user/auth/superadmin/login?adminId=${data.username}&adminPassword=${data.password}`
            );
            if (response.status === 200) {
                const userItem = response.data.body.data;
                const user: UserData = userItem[0];
                localStorage.setItem("username", user.username);
                localStorage.setItem("auth_token", user.auth_token);
                window.location.replace('/dashboard');
                toast.success("Login Successfully", {
                    autoClose: 10000
                });
            }
        } catch (error: any) {
            const errorMessage = error.response?.data?.message || "Login Failed";
            toast.error(errorMessage);
            console.error("Login error:", error);
        }
    };


    // const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    //     try {
    //         const response = await axios.post(
    //             `${APP_CONFIGURATION.APP_CONSTANT.AUTH_URL}user/auth/superadmin/login?adminId=${data.username}&adminPassword=${data.password}`
    //         );
    //         if (response.status === 200) {
    //             // Use toast.success for success message
    //             const userItem = response.data.body.data;
    //             const user: UserData = userItem[0];
    //             localStorage.setItem("username", user.username);
    //             localStorage.setItem("auth_token", user.auth_token);
    //             // navigate("/dashboard");
    //             window.location.replace('/dashboard')
    //             toast.success("Login Successfully",{
    //                  autoClose: 10000
    //             });
    //         }
    //     } catch (error) {
    //         toast.error("Login Failed");
    //     }
    // };

    return (
        <>
            <ToastContainer/>
            <main>
                <div className="container">
                    <section
                        className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div
                                    className="col-lg-5 col-md-6 d-flex flex-column align-items-center justify-content-center">
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="pt-4 pb-2">
                                                <h5 className="card-title text-center pb-0 fs-4">
                                                    Login to Your Account
                                                </h5>
                                                <p className="text-center small">
                                                    Enter your username &amp; password to login
                                                </p>
                                            </div>
                                            <form className="row g-3 needs-validation"
                                                  onSubmit={handleSubmit(onSubmit)}>
                                                <div className="col-12">
                                                    <label htmlFor="yourUsername" className="form-label">
                                                        Username
                                                    </label>
                                                    <div className="input-group has-validation">
                                                        <input
                                                            {...register("username", {required: `Username is required!`})}
                                                            name="username"
                                                            id="username"
                                                            type="text"
                                                            placeholder="Please Enter Username"
                                                            className="form-control p-2"
                                                        />
                                                    </div>
                                                    <ErrorMsg msg={errors.username?.message as string}/>
                                                </div>
                                                <div className="col-12">
                                                    <label htmlFor="yourPassword" className="form-label">
                                                        Password
                                                    </label>
                                                    <input
                                                        {...register("password", {required: `Password is required!`})}
                                                        name="password"
                                                        id="password"
                                                        type="password"
                                                        placeholder="Please Enter Password"
                                                        className="form-control p-2"
                                                    />
                                                </div>
                                                <ErrorMsg msg={errors.password?.message as string}/>
                                                <div className="col-12">
                                                    <button className="btn btn-primary w-100" type="submit">
                                                        Login
                                                    </button>
                                                </div>
                                                <div className="col-12">
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </main>
        </>
    );
};
export default Login;
