import React, {useState} from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

// @ts-ignore
const Layout = ({children}) => {
    const [isToggled, setToggled] = useState(false);
    const handleToggle = (childData: boolean | ((prevState: boolean) => boolean)) => {
        setToggled(childData);
    };

    return (
        <div className={isToggled ? 'toggle-sidebar' : ''}>
            <Navbar onToggle={handleToggle}/>
            <Sidebar/>
            <div>
                {children}
            </div>
        </div>
    );
};

export default Layout;