// import React, { useEffect, useState } from "react";
// import Layout from "../../layout/Layout/Layout";
// import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import axios from "axios";
// import { useNavigate } from "react-router-dom";
// // @ts-ignore
// import { AvField, AvForm } from "availity-reactstrap-validation";
// import { Button, FormGroup } from "reactstrap";
// import Loader from "../loader/loader";
// import { APP_CONFIGURATION } from "../../app-config/configuration";
//
// interface IFormInput {
//     productName: string;
//     SKU: number;
//     description: string;
//     price: string;
//     instock: string;
//     productCode: string;
//     height: string;
//     width: string;
//     productWeight: string;
//     gender: string;
//     goldPurity: string;
//     select: string;  // Added for category selection
// }
//
// interface Category {
//     categoryId: string;
//     name: string;
// }
//
// const CreateProduct: React.FC = () => {
//     const [loading, setLoading] = useState<boolean>(false);
//     const navigate = useNavigate();
//     const [category, setCategory] = useState<Category[]>([]);  // Initializing state as empty array
//
//     useEffect(() => {
//         getAllCategory();
//     }, []);
//
//     const gender = [
//         {
//             id:'MEN',
//             name:'MEN'
//         },
//         {
//             id:'WOMEN',
//             name:'WOMEN'
//         },
//         {
//             id:'KIDS',
//             name:'KIDS'
//         },
//         {
//             id:'UNISEX',
//             name:'UNISEX'
//         }
//     ]
//     const purity = [
//         {
//             id:'EIGHTEENK("18K")',
//             name:'EIGHTEENK("18K")'
//         },
//         {
//             id:'TWENTYTWOK("22K")',
//             name:'TWENTYTWOK("22K")'
//         },
//     ]
//
//     const handleValidSubmit = (e: any, values: IFormInput) => {
//         const payload = {
//             productName: values.productName,
//             SKU: values.SKU,
//             description: values.description,
//             price: values.price,
//             instock: values.instock,
//             productCode: values.productCode,
//             height: values.height,
//             width: values.width,
//             productWeight: values.productWeight,
//             gender: values.gender,
//             goldPurity: values.goldPurity,
//             categoryId: values.select
//         };
//
//         setLoading(true);
//
//         axios.post(`https://gatewayapi-uat.worldtradingleagues.com/wtl/admin/api/v1/add/product`, payload, {
//             headers: {
//                 Accept: "*/*",
//                 "Content-Type": "application/json",
//                 Authorization: `Bearer ${localStorage.getItem('auth_token')}`,  // Ensure string is not null
//             }
//         })
//         .then((res) => {
//             if (res.status === 200) {
//                 navigate('/products');
//             }
//             setLoading(false);
//         })
//         .catch((err) => {
//             console.error('Error occurred:', err);
//             setLoading(false);
//         });
//     };
//
//     const getAllCategory = () => {
//         axios.get(`https://gatewayapi-uat.worldtradingleagues.com/wtl/api/v1/ecom/categories`, {
//             headers: {
//                 'Content-Type': 'application/json; charset=utf-8',
//                 Authorization: `Bearer ${localStorage.getItem('auth_token') || ''}`,  // Ensure string is not null
//             },
//         })
//         .then((res) => {
//             if (res.data) {
//                 setCategory(res.data);
//             }
//         })
//         .catch(() => {
//             console.error('Error fetching categories');
//         });
//     };
//
//     return (
//         <Layout>
//             <main id="main" className="main">
//                 <div className="pagetitle mt-3">
//                     <h1>Add/Update Product</h1>
//                     <nav>
//                         <ol className="breadcrumb">
//                             <li className="breadcrumb-item">
//                                 <a href="#">Home</a>
//                             </li>
//                             <li className="breadcrumb-item active">Product</li>
//                         </ol>
//                     </nav>
//                 </div>
//                 <section className="section dashboard">
//                     <Loader loading={loading} />
//                     <div className="container">
//                         <div className="row">
//                             <div className="col-md-12 mt-5">
//                                 <AvForm onValidSubmit={handleValidSubmit}>
//                                     <div className="form-group">
//                                         <label htmlFor="productName" className='label-style'>Product Name*</label>
//                                         <AvField
//                                             required
//                                             name='productName'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="productName"
//                                             placeholder="Enter Product Name"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="SKU" className='label-style'>SKU*</label>
//                                         <AvField
//                                             required
//                                             name='SKU'
//                                             type="number"
//                                             className="form-control mt-2"
//                                             id="SKU"
//                                             placeholder="Enter SKU"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="description" className='label-style'>Description*</label>
//                                         <AvField
//                                             required
//                                             name='description'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="description"
//                                             placeholder="Enter Description"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="price" className='label-style'>Price*</label>
//                                         <AvField
//                                             required
//                                             name='price'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="price"
//                                             placeholder="Enter Price"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="instock" className='label-style'>In Stock*</label>
//                                         <AvField
//                                             required
//                                             name='instock'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="instock"
//                                             placeholder="Enter In Stock"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="productCode" className='label-style'>Product Code*</label>
//                                         <AvField
//                                             required
//                                             name='productCode'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="productCode"
//                                             placeholder="Enter Product Code"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="height" className='label-style'>Height*</label>
//                                         <AvField
//                                             required
//                                             name='height'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="height"
//                                             placeholder="Enter Height"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="width" className='label-style'>Width*</label>
//                                         <AvField
//                                             required
//                                             name='width'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="width"
//                                             placeholder="Enter Width"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="productWeight" className='label-style'>Product Weight*</label>
//                                         <AvField
//                                             required
//                                             name='productWeight'
//                                             type="text"
//                                             className="form-control mt-2"
//                                             id="productWeight"
//                                             placeholder="Enter Product Weight"
//                                         />
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="gender" className='label-style'>Gender*</label>
//                                         {/*<AvField*/}
//                                         {/*    required*/}
//                                         {/*    name='gender'*/}
//                                         {/*    type="text"*/}
//                                         {/*    className="form-control mt-2"*/}
//                                         {/*    id="gender"*/}
//                                         {/*    placeholder="Enter Gender"*/}
//                                         {/*/>*/}
//                                         <AvField required type="select" name="select" className="form-control mt-2">
//                                             {gender?.map((data) => (
//                                                 <option key={data.name} value={data.name}>
//                                                     {data.name}
//                                                 </option>
//                                             ))}
//                                         </AvField>
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="goldPurity" className='label-style'>Gold Purity*</label>
//                                         {/*<AvField*/}
//                                         {/*    required*/}
//                                         {/*    name='goldPurity'*/}
//                                         {/*    type="text"*/}
//                                         {/*    className="form-control mt-2"*/}
//                                         {/*    id="goldPurity"*/}
//                                         {/*    placeholder="Enter Gold Purity"*/}
//                                         {/*/>*/}
//                                         <AvField required type="select" name="select" className="form-control mt-2">
//                                             {purity?.map((data) => (
//                                                 <option key={data.name} value={data.name}>
//                                                     {data.name}
//                                                 </option>
//                                             ))}
//                                         </AvField>
//                                     </div>
//                                     <div className="form-group mt-3">
//                                         <label htmlFor="category" className='label-style'>Category*</label>
//                                         <AvField required type="select" name="select" className="form-control mt-2">
//                                             {category?.map((data) => (
//                                                 <option key={data.categoryId} value={data.categoryId}>
//                                                     {data.name}
//                                                 </option>
//                                             ))}
//                                         </AvField>
//                                     </div>
//                                     <FormGroup className='mt-4 text-center btn-style'>
//                                         <Button>Submit</Button>
//                                     </FormGroup>
//                                 </AvForm>
//                             </div>
//                         </div>
//                     </div>
//                 </section>
//             </main>
//         </Layout>
//     );
// };
//
// export default CreateProduct;
import React, { useEffect, useState } from "react";
import Layout from "../../layout/Layout/Layout";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import axios from "axios";
import { useNavigate } from "react-router-dom";
// @ts-ignore
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Button, FormGroup } from "reactstrap";
import Loader from "../loader/loader";
import { APP_CONFIGURATION } from "../../app-config/configuration";

interface IFormInput {
    productName: string;
    sku: string;
    description: string;
    price: string;
    instock: string;
    productCode: string;
    height: string;
    width: string;
    productWeight: string;
    gender: string;
    goldPurity: string;
    categoryId: string;
    productImages:string;
}

interface Category {
    categoryId: string;
    name: string;
}
type ProductSchema = {
    productId: string;
    productName: string;
    description: string;
    price: number;
    stock: number;
    goldPurity: string;
    gender: string;
    productDetailsCode: string;
    productDetailsHeight: string;
    productDetailsWidth: number;
    productDetailsWeight: number;
    metalDetails: any | null;
    solitaireDetails: any | null;
    diamondDetails: any | null;
    priceBreakup: any | null;
    productImages: string[];
    sku: string;
    categoryId:string;
};

const CreateProduct: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [category, setCategory] = useState<Category[]>([]);  // Initializing state as empty array
    const [productData, setProductData] = useState<ProductSchema>()
    const [isUpdate, setIsUpdate] = useState(false)
    const [productId, setProductId] = useState('')
    const [uploadImage, setUploadImage] = useState();
    const [productImages, setProductImages] = useState()
    console.log('fdf ', productImages)

    useEffect(() => {
        getAllCategory();
    }, []);

    const gender = [
        { id: 'MEN', name: 'MEN' },
        { id: 'WOMEN', name: 'WOMEN' },
        { id: 'KIDS', name: 'KIDS' },
        { id: 'UNISEX', name: 'UNISEX' }
    ];

    const purity = [
        { id: 'EIGHTEENK', name: 'EIGHTEENK' },
        { id: 'TWENTYTWOK', name: 'TWENTYTWOK' }
    ];
    useEffect(() => {
        const currentURL = window.location.href;
        const productId: any = currentURL.substring(currentURL.lastIndexOf("/") + 1);
        console.log('productId ', productId)
        if(productId){
            axios.get(`https://gatewayapi-uat.worldtradingleagues.com/wtl/api/v1/ecom/product-details?productID=${productId}`, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,  // Ensure token is passed
                }
            })
                    .then(async (res) => {
                         console.log('main ', res.data)
                        setProductData(res.data[0])
                        setProductId(res.data[0].productId)
                        setProductImages(res.data[0].productImages[0])
                        setIsUpdate(true)
                    }).catch(() => {
                })
        }
    }, []);

    const handleValidSubmit = (e: any, values: IFormInput) => {
        if(isUpdate){
            const payload = {
                productName: values.productName,
                sku: parseInt(values.sku),
                description: values.description,
                price: values.price,
                instock: values.instock,
                productCode: values.productCode,
                height: values.height,
                width: values.width,
                productWeight: values.productWeight,
                gender: values.gender,
                goldPurity: values.goldPurity,
                categoryId: values.categoryId,
                // productImages: uploadImage
                ...(uploadImage ? {productImages: uploadImage} : {})


            };
            axios.put(`https://gatewayapi-uat.worldtradingleagues.com/wtl/admin/api/v1/edit/${productId}`, payload, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,  // Ensure token is passed
                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        navigate('/products');
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error occurred:', err);
                    setLoading(false);
                });
            console.log('payload ', payload)
        }else {
            const payload = {
                ...values,  // Spread the values for the p ayload
                categoryId: values.categoryId,
                productImages:uploadImage
            };

            setLoading(true);
            axios.post(`https://gatewayapi-uat.worldtradingleagues.com/wtl/admin/api/v1/add/product`, payload, {
                headers: {
                    Accept: "*/*",
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem('auth_token')}`,

                }
            })
                .then((res) => {
                    if (res.status === 200) {
                        navigate('/products');
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    console.error('Error occurred:', err);
                    setLoading(false);
                });
        }

    };

    const getAllCategory = () => {
        axios.get(`https://gatewayapi-uat.worldtradingleagues.com/wtl/api/v1/ecom/categories`, {
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                Authorization: `Bearer ${localStorage.getItem('auth_token') || ''}`,  // Ensure string is not null
            },
        })
            .then((res) => {
                if (res.data) {
                    setCategory(res.data);
                }
            })
            .catch(() => {
                console.error('Error fetching categories');
            });
    };
    const selectedCategory = category.find((data: any) => data.id === productData?.categoryId);

    console.log('d ', selectedCategory?.name)

    const handleFileChange = (e: any) => {
        // Access the selected file from the event
        console.log('mai ', e.target.files[0])
        const selectedFile = e.target.files[0];
        setUploadImage(selectedFile)
    };


    return (
        <Layout>
            <main id="main" className="main">
                <div className="pagetitle mt-3">
                    <h1>Add/Update Product</h1>
                    <nav>
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="#">Home</a>
                            </li>
                            <li className="breadcrumb-item active">Product</li>
                        </ol>
                    </nav>
                </div>
                <section className="section dashboard">
                    <Loader loading={loading} />
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12 mt-5">
                                <AvForm onValidSubmit={handleValidSubmit}>
                                    <div className="form-group">
                                        <label htmlFor="productName" className='label-style'>Product Name*</label>
                                        <AvField
                                            required
                                            name='productName'
                                            type="text"
                                            className="form-control mt-2"
                                            id="productName"
                                            placeholder="Enter Product Name"
                                            value={productData ? productData.productName : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="sku" className='label-style'>SKU*</label>
                                        <AvField
                                            required
                                            name='sku'
                                            type="text"
                                            className="form-control mt-2"
                                            id="SKU"
                                            placeholder="Enter SKU"
                                            value={productData ? productData.sku : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="description" className='label-style'>Description*</label>
                                        <AvField
                                            required
                                            name='description'
                                            type="text"
                                            className="form-control mt-2"
                                            id="description"
                                            placeholder="Enter Description"
                                            value={productData ? productData.description : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="price" className='label-style'>Price*</label>
                                        <AvField
                                            required
                                            name='price'
                                            type="text"
                                            className="form-control mt-2"
                                            id="price"
                                            placeholder="Enter Price"
                                            value={productData ? productData.price : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="instock" className='label-style'>In Stock*</label>
                                        <AvField
                                            required
                                            name='instock'
                                            type="text"
                                            className="form-control mt-2"
                                            id="instock"
                                            placeholder="Enter In Stock"
                                            value={productData ? productData.stock : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="productCode" className='label-style'>Product Code*</label>
                                        <AvField
                                            required
                                            name='productCode'
                                            type="text"
                                            className="form-control mt-2"
                                            id="productCode"
                                            placeholder="Enter Product Code"
                                            value={productData ? productData.productDetailsCode : ''}

                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="height" className='label-style'>Height*</label>
                                        <AvField
                                            required
                                            name='height'
                                            type="text"
                                            className="form-control mt-2"
                                            id="height"
                                            placeholder="Enter Height"
                                            value={productData ? productData.productDetailsHeight : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="width" className='label-style'>Width*</label>
                                        <AvField
                                            required
                                            name='width'
                                            type="text"
                                            className="form-control mt-2"
                                            id="width"
                                            placeholder="Enter Width"
                                            value={productData ? productData.productDetailsWeight : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="productWeight" className='label-style'>Product Weight*</label>
                                        <AvField
                                            required
                                            name='productWeight'
                                            type="text"
                                            className="form-control mt-2"
                                            id="productWeight"
                                            placeholder="Enter Product Weight"
                                            value={productData ? productData.productDetailsWeight : ''}
                                        />
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="exampleInputPassword1" className='label-style'>Upload
                                            Product Image*</label>
                                        <AvField

                                            name='productImages' type="file" className="form-control mt-2"
                                            id="exampleInputPassword1"
                                            onChange={(e: any) => handleFileChange(e)}
                                            placeholder="Enter Author"/>
                                    </div>
                                    {productData &&
                                        <div className="form-group mt-3">
                                            <img src={productImages ? productImages : ''} alt='Blog Image'
                                                 height="200"/>
                                        </div>
                                    }

                                    <div className="form-group mt-3">
                                        {/*<AvField required type="select" name="gender" className="form-control mt-2">*/}
                                        {/*    <option value={productData ? productData.gender:''}>{productData ? productData.gender :'Select Gender'}</option>*/}
                                        {/*    {gender?.map((data) => (*/}
                                        {/*        <option key={data.id} value={data.name}>*/}
                                        {/*            {data.name}*/}
                                        {/*        </option>*/}
                                        {/*    ))}*/}
                                        {/*</AvField>*/}
                                        <label htmlFor="gender" className='label-style'>Gender*</label>
                                        <AvField
                                            required
                                            type="select"
                                            name="gender"
                                            className="form-control mt-2"
                                            value={productData?.gender || ''}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Please select gender',
                                                },
                                            }}
                                        >
                                            <option value=''>Select Gender</option>
                                            {gender?.map((data) => (
                                                <option key={data.id} value={data.id}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </div>
                                    <div className="form-group mt-3">

                                        {/*<AvField required type="select" name="goldPurity" className="form-control mt-2">*/}
                                        {/*    <option*/}
                                        {/*        value={productData ? productData.goldPurity : ''}>{productData ? productData.goldPurity : 'Select Gold Purity'}</option>*/}
                                        {/*    {purity?.map((data) => (*/}
                                        {/*        <option key={data.name} value={data.name}>*/}
                                        {/*            {data.name}*/}
                                        {/*        </option>*/}
                                        {/*    ))}*/}
                                        {/*</AvField>*/}
                                        <label htmlFor="goldPurity" className='label-style'>Gold Purity*</label>
                                        <AvField
                                            required
                                            type="select"
                                            name="goldPurity"
                                            className="form-control mt-2"
                                            value={productData?.goldPurity || ''}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Please select gold purity',
                                                },
                                            }}
                                        >
                                            <option value=''>Select Gold Purity</option>
                                            {purity?.map((data) => (
                                                <option key={data.name} value={data.name}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor="categoryId" className='label-style'>Category*</label>
                                        {/*<AvField required type="select" name="categoryId" className="form-control mt-2">*/}
                                        {/*    <option value=''>Select Category</option>*/}
                                        {/*    {category?.map((data) => (*/}
                                        {/*        <option key={data.categoryId} value={data.categoryId}>*/}
                                        {/*        {data.name}*/}
                                        {/*            </option>*/}
                                        {/*        ))}*/}
                                        {/*</AvField>*/}
                                        <AvField
                                            required
                                            type="select"
                                            name="categoryId"
                                            className="form-control mt-2"
                                            value={selectedCategory?.categoryId || ''}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: 'Please select Category',
                                                },
                                            }}
                                        >
                                            <option value=''>Select Gold Purity</option>
                                            {category?.map((data) => (
                                                <option key={data.categoryId} value={data.categoryId}>
                                                    {data.name}
                                                </option>
                                            ))}
                                        </AvField>
                                    </div>
                                    <FormGroup className='mt-4 text-center btn-style'>
                                        <Button>Submit</Button>
                                    </FormGroup>
                                </AvForm>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default CreateProduct;
