import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout/Layout";
import {Box} from "@mui/material";
import {DataGrid, GridCellParams} from "@mui/x-data-grid";
import axios from "axios";
import 'react-responsive-modal/styles.css';
import {Modal} from 'react-responsive-modal';
import {toast} from "react-toastify";
import Loader from "../loader/loader";
import {APP_CONFIGURATION} from "../../app-config/configuration";


interface Product {
  productId: string;
  productName: string;
  description: string;
  price: number;
  gender: string;
  goldPurity: "TWENTYTWOK";
  height: string;
  width: string;
  productWeight: string;
  category: "Locket Chain" | "Broach" | "Cuff Link";
  inStock: number;
  productImage: string; // Optional field, as some products don't have an image
}

const Products = () => {
    const [productData, setProductData] = useState<Product[]>([]);
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState<string>()
    const [loading, setLoading] = useState<boolean>(false)
    console.log('pu ', productData)

    const onOpenModal = (id: any) => {
        setOpen(true);
        setDeleteId(id)
    }
    const onCloseModal = () => setOpen(false);

    const handleDelete = () => {
    setLoading(true);

    axios.delete(`https://gatewayapi-uat.worldtradingleagues.com/wtl/admin/api/v1/delete/${deleteId}`, {
        headers: {
            "Content-Type": "multipart/form-data",
    
        }
    })
    .then((res) => {
        if (res.status >= 200 && res.status < 300) { // Check for success status codes
            setLoading(false);
            toast.success("Deleted Successfully");
            onCloseModal();
            getAllProduct();
        }
    })
    .catch((err) => {
        setLoading(false); // Ensure loading is turned off on error
        toast.error("Failed to delete the product"); // Provide feedback to the user
        console.error("Error deleting product:", err); // Log the error for debugging
    });
};

    const handleEdit = (id: string) => {
        window.location.replace(`/update-product/${id}`);
    }
    const columns = [
        {
            field: "srNo", // Update the field name to match the property name in the data
            headerName: "Sr. No.",
            width: 80,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as number)}</div>,
        },
        {
            field: "productName",
            headerName: "Product Name",
            width: 180,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as string)}</div>,
        },
        // {
        //     field: "desc",
        //     headerName: "Description",
        //     width: 300,
        //     renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as string)}</div>,
        // },
        {
            field: "category",
            headerName: "Category",
            width: 150,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>{(params.value as string)}</div>,
        },
        {
            field: "gender",
            headerName: "Gender",
            width: 150,
            renderCell: (params: GridCellParams) => (
                <div style={{textAlign: 'center'}}>
                    <img src={(params.value as string)} alt={(params.value as string)} style={{width: "100%"}}/>
                </div>
            ),
        },
        {
            field: "price",
            headerName: "Price",
            width: 120,
            renderCell: (params: GridCellParams) => (
                <div style={{textAlign: 'center'}}>
                    {(params.value as string)}
                </div>
            ),

        },
        {
            field: "inStock",
            headerName: "In Stock",
            width: 120,
            renderCell: (params: GridCellParams) => (
                <div style={{textAlign: 'center'}}>
                    {(params.value as string)}
                </div>
            ),

        },
        {
            field: "productImage",
            headerName: "Image",
            width: 250,
            renderCell: (params: GridCellParams) => (
                <div style={{textAlign: 'center'}}>
                    <img src={(params.value as string)} alt={(params.value as string)} style={{width: "100%",height:'100%'}}/>
                </div>
            ),

        },
        {
            field: "actions",
            headerName: "Actions",
            width: 190,
            renderCell: (params: GridCellParams) => <div style={{textAlign: 'center'}}>
                <button style={{background: '#012970', border: 'none'}} className='btn btn-danger mr-4' type="button"
                        onClick={() => handleEdit(params.row.id)}>
                    Edit
                </button>
                <button className='btn btn-danger' type="button" onClick={() => onOpenModal(params.row.id)}
                        style={{marginLeft: '10px'}}>
                    Delete
                </button>
            </div>
        }
    ];

    useEffect(() => {
        getAllProduct()
    }, []);
    const getAllProduct = () => {
        setLoading(true)
       axios
      .get(`https://gatewayapi-uat.worldtradingleagues.com/wtl/api/v1/ecom/product/search?page=0&size=1000`, {
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
        },
      })
      .then((res) => {
        if (res && res.data) {
            const productDataWithKeys = res.data.data.results.map((item: Product, index: number) => ({
                    ...item,
                    id: item.productId.toString(),
                    srNo: index + 1, // Add a new property for Sr. No.
                }));
            setProductData(productDataWithKeys)
            setLoading(false)
        }
      })
      .catch(() => {
        // notifyError('An error occurred');
      });
    }

    // @ts-ignore
    return (
        <>
            <Layout>
                <main id="main" className="main">
                    <div className="pagetitle mt-3">
                        <h1>Products</h1>
                        <nav>
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    Home
                                </li>
                                <li className="breadcrumb-item active">Products</li>
                            </ol>
                        </nav>
                    </div>
                    <section className="section dashboard">
                        <Loader loading={loading}/>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-12 mt-5">
                                    <Box>
                                        <DataGrid
                                            rows={productData}
                                            columns={columns}
                                            pagination
                                            // rowCount={blogData.length}
                                            // pageSizeOptions={[10, 15, 25]}
                                        />
                                    </Box>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
                <Modal open={open} onClose={onCloseModal} center>
                    <div className='p-5 text-center'>
                        <h3>Are You Sure You Want to Delete?</h3>
                        <div className="mt-3">
                            <button className="btn btn-danger ml-5" onClick={handleDelete}>Yes</button>
                            <button className="btn btn-secondary mr-4" style={{marginLeft: '10px'}}
                                    onClick={onCloseModal}>Cancel
                            </button>
                        </div>
                    </div>
                </Modal>
            </Layout>
        </>
    );
};

export default Products;
